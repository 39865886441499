//  A tokenData object looks like this:
  // {
  //   token: data.access_token,
  //   ttl: time in seconds from token creation to token expiry,
  //   expiresAt: timestamp for expiry time (set in VusJS app),
  //   user: user claims - { id: 123, name: My Name, permissions: [ permission1, permission2... ] }
  // }

export default {
  auth: {
    user: null,
    apiToken: null,// null or a tokenData object (defined in mutations.js)
    apiTokenIntervalId: null,
    isLoading: false
  },
  routes: [],
  error: {
    show: false,
    message: ''
  },
  clinics: {
    isLoading: false,
    data: []
  },
  dcs: {
    isLoading: false,
    data: [],
    dropdownDCs: []
  },
  dcBlockouts: {
    isLoading: false,
    data: []
  },
  clinicNPDists: {
    isLoading: false,
    data: [],
    dropdownDateRanges: []
  },
  customUrls: {
    isLoading: false,
    data: [],
    dropdownTypes: [
      { value: 'np-consult', text: 'NP Consult' }
    ]
  },
  slots: {
    isLoading: false,
    data: []
  },
  appointments: {
    isLoading: false,
    data: []
  },
  appointmentTypes: {
    isLoading: false,
    data: []
  },
  stripeProducts: {
    isLoading: false,
    data: []
  },
  settings: {
    isLoading: false,
    data: []
  }
}
