<template>
  <div>
    <nav-bar></nav-bar>
    <router-view/>
    <toast-handler></toast-handler>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import NavBar from '@/components/NavBar.vue'
import ToastHandler from '@/components/ToastHandler.vue'
import config from '@/config/index'

export default {
  name: 'App',
  components: {
    NavBar,
    ToastHandler
  },
  methods: {
    scratch() {
      console.log(`customUrls`, this.$store.state.customUrls.data)
    }
  },
  mounted() {
    const store = this.$store
    store.dispatch('compileStateRoutes', this.$router.options.routes)
    store.dispatch('setDistributionDateRanges')

    const apiTokenCheckPeriod = config.apiTokenCheckPeriod
    store.dispatch('setAPITokenIntervalId', setInterval(() => {
      //  If there's a state.auth.apiToken... check it's in date
      //    If expired, dispatch action to clear it, then redirect to login screen with error message
      //    If near expiry, trigger action ro refresh it
      if(!store.state.auth.apiToken || !store.state.auth.apiToken.expiresAt) return

      const now = dayjs().unix()

      //  If token has expired, clear API Token/user from store.state, then redirect to home / login
      if(store.state.auth.apiToken.expiresAt <= now) {
        store.dispatch('clearAPIToken').then(() => {
          if(this.$router.currentRoute.name != 'home') {
            console.log(`App.vue:45 - Redirecting -> home!`)
            this.$router.push('/')
          }
        })
        return
      }

      //  If token is getting close to expiring, dispatch action to refresh it
      const secondsLeft = store.state.auth.apiToken.expiresAt - now
      const router = this.$router

      if(secondsLeft <= (apiTokenCheckPeriod * 3)) {
        store.dispatch('refreshAPIToken')
        .catch(err => {
          store.dispatch('clearAPIToken')
          store.commit('showError', err)
          if(router.currentRoute.name != 'home') {
            console.log(`App.vue:62 - Redirecting -> home!`)
            router.push('/')
          }
        })
      }

    }, config.apiTokenCheckPeriod * 1000))
  }
}
</script>

<style lang="scss">
  @import "~@/assets/scss/vendors/bootstrap-vue/index";
</style>