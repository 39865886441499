import makeApiService from "./api-service";

let apiUrl = "https://api.willowlife.lh/api/v1", apiOrigin = "https://localhost";

switch (window.location.hostname) {
    case "api.willowlife.co.uk":
        apiUrl = "https://wapi.almostalldigital.co.uk/api/v1";
        apiOrigin = "http://api.willowlife.co.uk";
        break;
    case "admin.willowlife.co.uk":
        apiUrl = "https://api.willowlife.co.uk/api/v1";
        apiOrigin = "https://admin.willowlife.co.uk";
        break;
}

const apiService = makeApiService(apiUrl);
apiService.setHeader("X-Api-Origin", apiOrigin);
export default apiService;
