import dayjs from 'dayjs'
import jwt_decode from 'jwt-decode'
import apiUtils from '@/http/api-utils'
import willowApi from '@/http/willow-api-service'
import config from '@/config/index'
import { cloneDeep } from 'lodash'
import { eventBus } from '@/main'

function processAPITokenData(state, tokenData) {
  localStorage.setItem(`${config.localStorageNamespace}.apiToken`, JSON.stringify(tokenData))
  state.auth.apiToken = tokenData
  state.auth.user = tokenData.user
  willowApi.setHeader('Authorization', `Bearer ${tokenData.token}`)
}

//  EG [ customUrls ], [ clinics ], 'location_id', 'id', 'clinic'
function decorateEntitiesFromMetadata(entities, metadata, entityKey, metadataKey, relatedFieldName) {
  return entities.map((entity) => {
    if(!entity[entityKey]) {//  EG customUrl.location_id is 0 or null
      entity[relatedFieldName] = null
      return entity
    }

    entity[relatedFieldName] = metadata.find((m) => { return m[metadataKey] == entity[entityKey] }) || null

    return entity
  })
}

export default {
  compileStateRoutes(state, routes) { state.routes = routes },
  setAuthIsLoading(state, val) { state.auth.isLoading = val ? true : false },
  resetUser(state) { state.auth.user = null },
  //  If we get a new token in a response from API, it comes with a TTL from which we can calculate an expiry timestamp
  processNewAPIToken(state, data) {
    processAPITokenData(state, {
      token: data.access_token,
      ttl: data.expires_in,
      expiresAt: dayjs().add(data.expires_in, 's').unix(),
      user: jwt_decode(data.access_token).user
    })
  },
  processLocallyStoredTokenData(state, tokenData) {// Only called if there is an in-date locally-stored tokenData object
    // console.log(`mu.processLocallyStoredTokenData.tokenData`, tokenData)
    processAPITokenData(state, tokenData)
  },
  removeAPITokenFromLocalStorage() { localStorage.removeItem(`${config.localStorageNamespace}.apiToken`) },
  setAPITokenIntervalId(state, id) { state.auth.apiTokenIntervalId = id },
  setUserFromAPIToken(state, token) {
    state.auth.user = jwt_decode(token).user
    willowApi.setHeader('Authorization', `Bearer ${token}`)
  },
  clearAPIToken(state) {
    state.auth.user = null
    state.auth.apiToken = null
  },
  setClinicsIsLoading(state, val) { state.clinics.isLoading = val ? true : false },
  setClinics(state, clinics) {
    state.clinics.data = apiUtils.dateStringsToObjects(clinics, ['created_at', 'updated_at', 'deleted_at'])
  },
  setClinicNPDistsIsLoading(state, val) { state.clinicNPDists.isLoading = val ? true : false },
  setClinicNPDists(state, npDists) {
    state.clinicNPDists.data = apiUtils.dateStringsToObjects(npDists, ['starts_at', 'ends_at', 'created_at', 'updated_at', 'deleted_at'])
  },
  setDCsIsLoading(state, val) { state.dcs.isLoading = val ? true : false },
  setDCs(state, dcs) {
    state.dcs.data = apiUtils.dateStringsToObjects(dcs, [ "created_at", "updated_at", "deleted_at"]);
  },
  setDCBlockoutsIsLoading(state, val) { state.dcBlockouts.isLoading = val ? true : false },
  setDCBlockouts(state, dcBlockouts) {
    state.dcBlockouts.data = apiUtils.dateStringsToObjects(dcBlockouts, ['created_at', 'updated_at'])
  },
  setDropdownDCs(state, dcs) {
    state.dcs.dropdownDCs = dcs.map(dc => {
      return {
        value: dc.id,
        text: `${dc.first_name} ${dc.last_name}`
      }
    })
    state.dcs.dropdownDCs.unshift({ value: null, text: "Choose one" })
  },
  setDistributionDateRanges(state) {
    let from = dayjs().hour(0).minute(0).second(0)
    state.clinicNPDists.dropdownDateRanges = []

    for (let i = 0; i < 6; i++) {
      state.clinicNPDists.dropdownDateRanges.push({
        from: from.toDate(), 
        to: from.add(1, 'M').subtract(1, 'd').hour(23).minute(59).second(59).toDate()
      })
      from = from.add(1, 'M')
    }
  },
  setCustomUrlsIsLoading(state, val) { state.customUrls.isLoading = val },
  setCustomUrls(state, data) {
    let customUrls = apiUtils.dateStringsToObjects(data, ['expires_at', 'created_at', 'updated_at', 'deleted_at'])
    customUrls = decorateEntitiesFromMetadata(customUrls, state.clinics.data, 'location_id', 'id', 'clinic')
    customUrls = decorateEntitiesFromMetadata(customUrls, state.dcs.data, 'dc_id', 'id', 'dc')
    customUrls = decorateEntitiesFromMetadata(customUrls, state.stripeProducts.data, 'stripe_product_id', 'id', 'stripe_product')
    state.customUrls.data = customUrls
  },
  setSettingsIsLoading(state, val) { state.settings.isLoading = val ? true : false },
  setSettings(state, settings) { state.settings.data = cloneDeep(settings); },
  setReferralSources(state, sources) { state.settings.data.referral_sources = sources },
  setConsultRoomTypeIds(state, consultRoomTypeIds) { state.settings.data.consult_room_appt_type_ids = consultRoomTypeIds },
  setBookingsHistoryWeeks(state, weeks) { state.settings.data.bookings_history_weeks = weeks },
  setBookingsMinNotice(state, minNotice) { state.settings.data.bookings_min_notice = minNotice },
  setBookingsMaxDailySlots(state, slots) { state.settings.data.bookings_max_daily_slots = slots },
  setTargetVariationCriterionPercent(state, val) { state.settings.data.target_variation_criterion_percent = val },
  setStaffEmail(state, email) { state.settings.data.staff_email = email },
  setDefaultStripeProductId(state, defaultStripeProductId) { state.settings.data.default_stripe_product_id = defaultStripeProductId },
  clearSlotsAndAppointments(state) {
    state.slots.data = []
    state.appointments.data = []
    state.slots.isLoading = false
    state.appointments.isLoading = false
  },
  setSlotsIsLoading(state, isLoading) { state.slots.isLoading = isLoading },
  setSlots(state, slots) { state.slots.data = apiUtils.dateStringsToObjects(slots, ['starts_at', 'ends_at', 'created_at', 'updated_at']) },
  setAppointmentsIsLoading(state, isLoading) { state.appointments.isLoading = isLoading },
  setAppointments(state, appointments) { state.appointments.data = apiUtils.dateStringsToObjects(appointments, ['starts_at', 'ends_at']) },
  setAppointmentTypesIsLoading(state, val) { state.appointmentTypes.isLoading = val ? true : false },
  setAppointmentTypes(state, appointmentTypes) {
    state.appointmentTypes.data = apiUtils.dateStringsToObjects(appointmentTypes, ['created_at', 'updated_at'])
  },
  setStripeProductsIsLoading(state, val) { state.stripeProducts.isLoading = val ? true : false },
  setStripeProducts(state, stripeProducts) {
    state.stripeProducts.data = apiUtils.dateStringsToObjects(stripeProducts, ['created_at', 'updated_at'])
    console.log(`mut.setStripeProducts`, state.stripeProducts.data)
  },
  toast(state, toastConfig) {
    state.showToast = true
    eventBus.$emit('toast', toastConfig)
  },
  showError(state, err) {
    state.error.message = err
    console.log(`mutations.showError`, err)
    //  @TODO: implement displaying the error...
  }
}
