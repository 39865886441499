import dayjs from 'dayjs'
import config from '@/config/index'

export default {
  isAPITokenInDate(state) {
    // {
    //   token: hashed JWT,
    //   ttl: in seconds, 3600 == 1 hour,
    //   expiresAt: unix timestamp calculated locally in VueJS,
    //   user: {}
    // }

    if (!state.auth.apiToken) return false
    const timeLeftOnToken = state.auth.apiToken.expiresAt - dayjs().unix()
    return (timeLeftOnToken >= (config.apiTokenCheckPeriod * 2))
  },
  isLoggedIn(state, getters) {
    if(!state.auth.user) return false// both elements are set from locally stored apiToken, or on login
    return getters.isAPITokenInDate
  },
  user(state) { return state.auth.user },
  userRoutes(state, getters) {// All routes available to logged-in user, depending on their permissions
    const userPermissions = state.auth.user.permissions
    const isLoggedIn = getters.isLoggedIn
    const routes = state.routes

    const result = routes.filter(route => {
      if (userPermissions.includes('sysadmin')) return true//  Sysadmin can access all routes
      if (!route.meta.requiresAuth) return true//  Route doesn't require login - is public
      if (!isLoggedIn) return false//  Route requires login, user is not logged in
      if (!(route.meta.permissions && route.meta.permissions.length)) return true// Route requires login but no specific permissions

      //  Return true if user has any of the permissions accepted by route
      const userPermissionsMatchingRoutePermissions = route.meta.permissions.reduce((accumulator, current) => {
        if(userPermissions.includes(current)) accumulator.push(current)
        return accumulator
      }, [])

      return userPermissionsMatchingRoutePermissions.length >= 1
    })
    
    return result
  },
  clinics(state) { return state.clinics.data },
  dropdownClinics(state) {
    if(!state.clinics.data.length) return []
    let clinics = state.clinics.data.map(c => { return { value: c, text: c.name } })
    clinics.unshift({ value: null, text: 'Choose one' })
    return clinics
  },
  isLoadingClinics(state) { return state.clinics.isLoading },
  hasClinics(state) { return !state.clinics.isLoading && (state.clinics.data.length >= 1) },
  clinicNPDists(state) { return state.clinicNPDists.data },
  isLoadingClinicNPDists(state) { return state.clinicNPDists.isLoading },
  dropdownDateRanges(state) {
    return state.clinicNPDists.dropdownDateRanges.map(dr => {
      return { value: dr, text: `${dayjs(dr.from).format('DD MMM YYYY')} - ${dayjs(dr.to).format('DD MMM YYYY')}` }
    })
  },
  isLoadingCustomUrls(state) { return state.customUrls.isLoading },
  hasCustomUrls(state) { return state.customUrls.data.length >= 1 },
  customUrls(state) { return state.customUrls.data },
  dropdownCustomUrlTypes(state) { return state.customUrls.dropdownTypes },

  isLoadingDCs(state) { return state.dcs.isLoading },
  hasDCs(state) { return !state.dcs.isLoading && (state.dcs.data.length >= 1) },
  dcs(state) { return state.dcs.data },
  dropdownDCs(state) { return state.dcs.dropdownDCs },
  dcExcludedRefSources(state) { return dc => {
    return state.settings.data.referral_sources.map(({ id, source }) => {
      return { id, source, is_excluded: (dc.excluded_ref_source_ids.indexOf(id) >= 0) };
    });
  }},

  isLoadingDCBlockouts(state) { return state.dcBlockouts.isLoading },
  hasDCBlockouts(state) { return !state.dcBlockouts.isLoading && (state.dcBlockouts.data.length >= 1) },
  dcBlockouts(state) { return state.dcBlockouts.data },

  isLoadingStripeProducts(state) { return state.stripeProducts.isLoading },
  hasStripeProducts(state) { return !state.stripeProducts.isLoading && (state.stripeProducts.data.length >= 1) },
  stripeProducts(state) { return state.stripeProducts.data },

  //  Settings
  isLoadingSettings(state) { return state.settings.isLoading },
  referralSources(state) {
    return (state.settings.data.referral_sources && state.settings.data.referral_sources.length) ? state.settings.data.referral_sources : []
  },
  consultRoomApptTypeIds(state) {
    return (state.settings.data.consult_room_appt_type_ids && state.settings.data.consult_room_appt_type_ids.length) ? state.settings.data.consult_room_appt_type_ids : []
  },
  bookingsHistoryWeeks(state) { return state.settings.data.bookings_history_weeks || 4 },
  bookingsMinNotice(state) { return state.settings.data.bookings_min_notice || { unit: 'hour', quantity: 3 } },
  bookingsMaxDailySlots(state) { return state.settings.data.bookings_max_daily_slots || 3 },
  isLoadingSlots(state) { return state.slots.isLoading },
  slots(state) { return state.slots.data },
  hasSlots(state) { return state.slots.data && state.slots.data.length && (state.slots.data.length >= 1) },
  isLoadingAppointments(state) { return state.appointments.isLoading },
  appointments(state) { return state.appointments.data },
  appointmentTypes(state) { return state.appointmentTypes.data },
  hasAppointments(state) { return state.appointments.data && state.appointments.data.length && (state.appointments.data.length >= 1) },
  targetVariationCriterionPercent(state) { return state.settings.data.target_variation_criterion_percent || 10.00 },
  staffEmail(state) { return state.settings.data.staff_email || '' },
  selectedReferralSources(state) {
    if (!state.settings.data.referral_sources) return [];
    return (state.settings.data.referral_sources.selected && state.settings.data.referral_sources.selected.length) ? state.settings.data.referral_sources.selected : []
  },

  allSettings(state) { return state.settings.data },
  customUrlValidationErrors(state) { return state.customUrls.validationErrors }
}
