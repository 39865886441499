import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

//  Need a Vue instance as an event bus for EG event-driven toasts/alerts
export const eventBus = new Vue();

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false

//  Need to check whether user has an in-date JWT before creating main Vue instance, so router doesn't
//  think user's not logged in, which would make it redirect user to home if user tries to navigate 
//  directly to an auth-guarded route like "https://admin.willowchiro.com/settings"
store.dispatch('checkLocalStorageForToken')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
