import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
      requiresAuth: false
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
    meta: {
      title: 'Settings',
      requiresAuth: true,
      permissions: ['admin', 'can-edit-settings']
    }
  },
  {
    path: '/distribution-np',
    name: 'distribution-np',
    component: () => import(/* webpackChunkName: "distributions" */ '../views/DistributionsView.vue'),
    meta: {
      title: 'Distributions',
      requiresAuth: true,
      permissions: ['admin', 'can-edit-distributions', 'can-edit-distributions-np']
    }
  },
  {
    path: '/custom-urls',
    name: 'custom-urls',
    component: () => import(/* webpackChunkName: "custom-urls" */ '../views/CustomURLsView.vue'),
    meta: {
      title: 'Custom URLs',
      requiresAuth: true,
      permissions: ['admin', 'can-edit-custom-urls']
    }
  },
  {
    path: '/sandbox',
    name: 'sandbox',
    component: () => import(/* webpackChunkName: "custom-urls" */ '../views/SandboxView.vue'),
    meta: {
      title: 'Sandbox',
      requiresAuth: true,
      permissions: ['admin']
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      title: 'Log out',
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('logout').then(() => {
        return next('/')
      })
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
