import dayjs from 'dayjs'
import config from '@/config/index'
import willowApi from '@/http/willow-api-service'

export default {
    toast({ commit }, toastConfig) { commit('toast', toastConfig) },
    successToast({ commit }, message) { commit('toast', { message, variant: 'success' }) },
    errorToast({ commit }, message) { commit('toast', { message, variant: 'danger' }) },
    logout({ commit }) {
        commit('resetUser')
        commit('removeAPITokenFromLocalStorage')
    },
    login({ commit, dispatch }, credentials) {
        commit('setAuthIsLoading', true)
        return willowApi.post('/login', credentials)
            .then(response => {
                commit('processNewAPIToken', response.data)
            })
            .then(() => dispatch('getMetadata'))
    },
    compileStateRoutes({ commit }, routes) { commit('compileStateRoutes', routes) },
    setDistributionDateRanges({ commit }) { commit('setDistributionDateRanges') },
    setAPITokenIntervalId({ commit }, id) { commit('setAPITokenIntervalId', id) },
    clearAPIToken({ commit }) {
        return new Promise((resolve) => {
            commit('clearAPIToken')
            resolve()
        })
    },
    checkLocalStorageForToken({ commit, dispatch }) {
        const tokenJSON = localStorage.getItem(`${config.localStorageNamespace}.apiToken`)

        if (tokenJSON === null) return

        const tokenData = JSON.parse(tokenJSON)
        const ttl = tokenData.expiresAt - dayjs().unix()

        if (ttl >= (config.apiTokenCheckPeriod * 3)) {
            commit('processLocallyStoredTokenData', tokenData)
            dispatch('getMetadata')
        }
    },
    refreshAPIToken({ commit }) {
        return willowApi.post('/refresh-token')
            .then(response => {
                console.log(`actions.refreshAPIToken.response.data`, response.data)
                commit('processNewAPIToken', response.data)
            })
    },
    getMetadata({ commit }) {
        commit('setClinicsIsLoading', true)
        commit('setDCsIsLoading', true)
        commit('setAppointmentTypesIsLoading', true)
        commit('setStripeProductsIsLoading', true)
        commit('setSettingsIsLoading', true)
        return willowApi.get('/metadata')
            .then(response => {
                commit('setSettings', response.data.settings);//    MUST be before setDCs
                commit('setClinics', response.data.clinics.data)
                commit('setDCs', response.data.dcs.data)
                commit('setAppointmentTypes', response.data.appointment_types.data)
                commit('setStripeProducts', response.data.stripe_products.data)
                commit('setDropdownDCs', response.data.dcs.data)
                commit('setClinicsIsLoading', false)
                commit('setDCsIsLoading', false)
                commit('setAppointmentTypesIsLoading', false)
                commit('setStripeProductsIsLoading', false)
                commit('setSettingsIsLoading', false)
            })
            .catch(err => {
                commit('showError', err)
                commit('setClinicsIsLoading', false)
                commit('setDCsIsLoading', false)
                commit('setAppointmentTypesIsLoading', false)
                commit('setStripeProductsIsLoading', false)
                commit('setSettingsIsLoading', false)
            })
    },
    saveClinicAllowOnlineBookings({ commit }, { id, allow_online_bookings }) {
        return willowApi.post(`/clinics/allow-online-bookings`, { id, allow_online_bookings })
            .then(response => { commit('setClinics', response.data) })
            .catch(err => commit('showError', err))
    },
    saveClinicConsultRoomCount({ commit }, { id, consult_room_count }) {
        return willowApi.post(`/clinics/consult-room-count`, { id, consult_room_count })
            .then(response => { commit('setClinics', response.data) })
            .catch(err => commit('showError', err))
    },
    getClinicNPDists({ commit }, clinic) {
        commit('setClinicNPDistsIsLoading', true)
        commit('setClinicNPDists', [])
        return willowApi.get(`/clinics/${clinic.id}/np-distributions`)
            .then(response => commit('setClinicNPDists', response.data.data))
            .then(() => commit('setClinicNPDistsIsLoading', false))
            .catch(err => {
                commit('showError', err)
                commit('setClinicNPDistsIsLoading', false)
            })
    },
    getDCBlockouts({ commit }, dc) {
        commit('setDCBlockoutsIsLoading', true)
        commit('setDCBlockouts', [])
        return willowApi.get(`/dcs/${dc.id}/blockouts`)
            .then(response => commit('setDCBlockouts', response.data.data))
            .then(() => commit('setDCBlockoutsIsLoading', false))
            .catch(err => {
                commit('showError', err)
                commit('setDCBlockoutsIsLoading', false)
            })
    },
    saveDCBlockout({ commit }, blockout) {
        return willowApi.post(`/dcs/blockout`, blockout)
            .then(response => commit('setDCBlockouts', response.data.data))
    },
    deleteDCBlockout({ commit }, blockout) {
        return willowApi.delete(`/dcs/blockouts/${blockout.id}`)
            .then(response => commit('setDCBlockouts', response.data.data))
    },
    saveNPDist({ commit }, dist) {
        return willowApi.post(`/np-distributions`, dist)
            .then(response => commit('setClinicNPDists', response.data.data))
            .catch(err => commit('showError', err))
    },
    deleteNPDist({ commit }, dist) {
        commit('setClinicNPDistsIsLoading', true)
        return willowApi.delete(`/np-distributions/${dist.id}`)
            .then(response => {
                commit('setClinicNPDistsIsLoading', false)
                commit('setClinicNPDists', response.data.data)
            })
            .catch(err => {
                commit('setClinicNPDistsIsLoading', false)
                commit('showError', err)
            })
    },
    setCustomUrlsIsLoading({ commit }, val) { commit('setCustomUrlsIsLoading', val) },
    getCustomUrls({ commit }) {
        commit('setCustomUrlsIsLoading', true)
        return willowApi.get('/custom-urls')
            .then(response => commit('setCustomUrls', response.data.data))
            .then(() => commit('setCustomUrlsIsLoading', false))
            .catch(err => {
                commit('showError', err)
                commit('setCustomUrlsIsLoading', false)
            })
    },
    deleteCustomUrl({ commit }, url) {
        commit('setCustomUrlsIsLoading', true)
        return willowApi.delete(`/custom-urls/${url.id}`)
            .then(response => {
                commit('setCustomUrlsIsLoading', false)
                commit('setCustomUrls', response.data.data)
            })
            .catch(err => {
                commit('setCustomUrlsIsLoading', false)
                commit('showError', err)
            })
    },
    saveCustomUrl({ commit }, url) {
        url.clinic_id = url.clinic ? url.clinic.id : 0
        url.dc_id = url.dc ? url.dc.id : 0
        return willowApi.post('/custom-urls', url)
            .then(response => {
                commit('setCustomUrls', response.data.data)
            })
            .catch(err => {
                if (err.response && err.response.status && err.response.data) {
                    switch (err.response.status) {
                        case 422:
                            throw err
                        default:
                            commit('showError', err)
                    }
                }
                else {
                    commit('showError', err)
                }
            })
    },
    saveReferralSources({ commit }, sources) {
        return willowApi.post('/settings/referral-sources', { referral_sources: sources })
            .then(response => {
                commit('setReferralSources', response.data.referral_sources)
            })
    },
    saveConsultRoomTypeIds({ commit }, consultRoomTypeIds) {
        return willowApi.post('/settings/consult-room-appt-type-ids', { consult_room_appt_type_ids: consultRoomTypeIds })
            .then(response => {
                commit('setConsultRoomTypeIds', response.data.consult_room_appt_type_ids)
            })
    },
    saveBookingsHistoryWeeks({ commit }, weeks) {
        return willowApi.post('/settings/bookings-history-weeks', { weeks: weeks })
            .then(response => {
                commit('setBookingsHistoryWeeks', response.data.bookings_history_weeks)
            })
    },
    saveBookingsMinNotice({ commit }, minNotice) {
        return willowApi.post('/settings/bookings-min-notice', minNotice)
            .then(response => {
                commit('setBookingsMinNotice', response.data.bookings_min_notice)
            })
    },
    saveBookingsMaxDailySlots({ commit }, slots) {
        return willowApi.post('/settings/bookings-max-daily-slots', { slots: slots })
            .then(response => {
                commit('setBookingsMaxDailySlots', response.data.bookings_max_daily_slots)
            })
    },
    saveTargetVariationCriterionPercent({ commit }, val) {
        return willowApi.post('/settings/target-variation-criterion-percent', { target_variation_criterion_percent: val })
            .then(response => {
                commit('setTargetVariationCriterionPercent', response.data.target_variation_criterion_percent)
            })
    },
    saveStaffEmail({ commit }, email) {
        return willowApi.post('/settings/staff-email', { email: email })
            .then(response => {
                commit('setStaffEmail', response.data.staff_email)
            })
    },
    // saveDCIsActive({ commit }, { id, is_active }) {
    //   return willowApi.post(`/dcs/is-active`, { id, is_active })
    //     .then(response => { commit('setDCs', response.data.data) })
    // },
    saveDCAllowOnlineBookings({ commit }, { id, allow_online_bookings }) {
        return willowApi.post(`/dcs/allow-online-bookings`, { id, allow_online_bookings })
            .then(response => { commit('setDCs', response.data.data) })
    },
    saveDCAreReferralsAllowed({ commit }, { id, are_referrals_allowed }) {
        return willowApi.post(`/dcs/are-referrals-allowed`, { id, are_referrals_allowed })
            .then(response => { commit('setDCs', response.data.data) })
    },
    saveDCAreReaxAllowed({ commit }, { id, are_reax_allowed }) {
        return willowApi.post(`/dcs/are-reax-allowed`, { id, are_reax_allowed })
            .then(response => { commit('setDCs', response.data.data) })
    },
    saveDCDefaultSplitPercent({ commit }, { id, default_split_percent }) {
        return willowApi.post(`/dcs/default-split-percent`, { id, default_split_percent })
            .then(response => { commit('setDCs', response.data.data) })
    },
    saveDCConsultationLength({ commit }, { id, consultation_length }) {
        return willowApi.post(`/dcs/consultation-length`, { id, consultation_length })
            .then(response => { commit('setDCs', response.data.data) })
    },
    saveDCMaxSlotsCap({ commit }, { id, max_slots_cap }) {
        return willowApi.post(`/dcs/max-slots-cap`, {
            id, max_slots_cap: {
                quantity: parseInt(max_slots_cap.quantity),
                days: parseInt(max_slots_cap.days)
            }
        })
            .then(response => { commit('setDCs', response.data.data) })
    },
    saveDCExcludedRefSourceIds({ commit }, { id, excluded_ref_source_ids }) {
        console.log(`ax.saveDCExcludedRefSourceIds.excluded_ref_source_ids`, excluded_ref_source_ids);
        return willowApi.post(`/dcs/excluded-ref-source-ids`, { id, excluded_ref_source_ids })
        .then(response => { commit('setDCs', response.data.data) });
    },
    saveStripeProduct({ commit }, product) {
        const productForSave = { ...product };
        delete productForSave.stripe_product;
        return willowApi.post(`/stripe-products`, productForSave)
        .then(response => {
            console.log(`mut.saveStripeProduct.response.data`, response.data);
            commit('setStripeProducts', response.data.data);

        })
    },
    deleteStripeProduct({ commit }, product) {
        if(product.id == 0) return;//   Can't delete virtual free consult product
        return willowApi.delete(`/stripe-products/${product.id}`)
            .then(response => commit('setStripeProducts', response.data.data))
    },
    saveDefaultStripeProductId({ commit }, productId) {
        return willowApi.post('/settings/default-stripe-product-id', { id: productId })
            .then(response => {
                commit('setDefaultStripeProductId', response.data.default_stripe_product_id);
            })
    },
    saveDCShifts({ commit }, { id, shifts }) {
        return willowApi.post(`/dcs/shifts`, { id, shifts })
            .then(response => { commit('setDCs', response.data.data) })
    },
    getSlots({ commit }) {
        commit('setSlotsIsLoading', true)
        return willowApi.get(`/slots`, { location_id: 6, dc_id: 203, from: '2022-06-27', to: '2022-07-01' })
            .then(response => {
                commit('setSlots', response.data.data)
                commit('setSlotsIsLoading', false)
            })
    },
    getAppointments({ commit }) {
        commit('setAppointmentsIsLoading', true)
        return willowApi.get(`/appointments`, { location_id: 6, dc_id: 203, from: '2022-06-27', to: '2022-07-01' })
            .then(response => {
                commit('setAppointments', response.data.data)
                commit('setAppointmentsIsLoading', false)
            })
    },
    scratch() { return willowApi.get('/scratch') }
}
